import { useStaticQuery, graphql } from 'gatsby';

export const useStaticQueryImages = () => {
  const ImagesQueries = useStaticQuery(
    graphql`
      query ImagesQueries {
        headImg: file(relativePath: { eq: "head-3.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        signInImg: file(relativePath: { eq: "sweal-head.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        profilePageImg: file(relativePath: { eq: "profile-img-2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        mealsImg: file(relativePath: { eq: "sweal-head-old.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ingredientsImg: file(relativePath: { eq: "head-2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        equipmentsImg: file(relativePath: { eq: "kitchen-1.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        cookingTogetherImg: file(relativePath: { eq: "cooking-together.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        # allSitePageImages: allSitePage {
        #   edges {
        #     node {
        #       context {
        #         nameID
        #       }
        #       generatedImage {
        #         childImageSharp {
        #           gatsbyImageData {
        #             ...GatsbyImageSharpgatsbyImageData
        #           }
        #         }
        #       }
        #     }
        #   }
        # }
      }
    `
  );
  return { ...ImagesQueries };
};

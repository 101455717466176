import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Divider,
  TextField,
} from '@mui/material';

import { useAuthState } from 'react-firebase-hooks/auth';
import { navigate } from 'gatsby';
import { StandardLayout } from '../layouts';
import { Icon, Text } from '../components';
import { useStaticQueryImages } from '../hooks/static-queries-hooks';
import { auth, doGoogleLogin } from '../firebase';

const title = `Login`;
const subtitle = `Melde dich an, oder registriere dich.`;
const contentText = [
  `Du kannst dich mit deiner E-Mail Adresse anmelden, oder ganz bequem ein bereits vorhandenes Konto wählen.`,
];

{
  /* <Box>
<Box m={1}>
  <TextField
    required
    id="outlined-required"
    label="E-Mail"
    fullWidth
  />
</Box>
<Box m={1}>
  <TextField
    required
    id="outlined-password-input"
    label="Password"
    type="password"
    autoComplete="current-password"
    fullWidth
  />
</Box>
</Box> */
}

export default function LoginPage() {
  const [user, loading, error] = useAuthState(auth);
  const { signInImg } = useStaticQueryImages();

  useEffect(() => {
    if (loading === false && user != null) {
      navigate('/dashboard');
    }
  }, [loading, user]);

  const handleLogin = (cb) => {
    cb.then((result) => {
      // // This gives you a Google Access Token. You can use it to access the Google API.
      // const token = credential.accessToken;
      // // The signed-in user info.
      // const user = result.user;
      // // ...
    }).catch((error) => {
      // // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // // The email of the user's account used.
      // const email = error.email;
      // // The AuthCredential type that was used.
      // // ...
    });
  };

  return (
    <StandardLayout
      imageProps={{
        imageData: signInImg,
        alt: 'Landingpage Image, showing a lavish meal.',
      }}
      headerProps={{
        title,
        subtitle,
      }}
    >
      <Box p={4}>
        <Container maxWidth="sm">
          {contentText.map((c) => (
            <Text.Content.Text
              key={c
                .substring(0, c.length > 10 ? 10 : c.length)
                .replace(' ', '')}
              text={c}
            />
          ))}

          <Typography component="div" align="center">
            <Box m={1}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleLogin(doGoogleLogin())}
                startIcon={
                  <Icon
                    iconName="mdiGoogle"
                    title="Login With Google Account"
                    size={1}
                  />
                }
              >
                Google
              </Button>
            </Box>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
            <Text.Content.SideInfoText text="coming soon" />
            <Box m={1}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                disabled
                startIcon={
                  <Icon
                    iconName="mdiFacebook"
                    title="Login With Facebook Account"
                    size={1}
                  />
                }
              >
                Facebook
              </Button>
            </Box>

            <Box m={1}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                disabled
                startIcon={
                  <Icon
                    iconName="mdiTwitter"
                    title="Login With Facebook Account"
                    size={1}
                  />
                }
              >
                Twitter
              </Button>
            </Box>
            <Box m={1}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                disabled
                startIcon={
                  <Icon
                    iconName="mdiApple"
                    title="Login With Facebook Account"
                    size={1}
                  />
                }
              >
                Apple
              </Button>
            </Box>
          </Typography>
        </Container>
      </Box>
    </StandardLayout>
  );
}
